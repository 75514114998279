import React, { useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isArray, last, pick } from 'lodash';
import { error } from 'react-toastify-redux';
import ProfileSocialForm from './ProfileSocialForm';
import ProfileAvatar from './ProfileAvatar';
import { customerSelector, customerPublicFilesSelector } from '../../../store/selectors/DefaultSelectors';
import requestStatusSelector from '../../../store/selectors/requestStatusSelector';
import * as actions from '../../../store/actions';
import {
  changeCustomerProfileIsPublic,
  changeCustomerProfilePublicFields,
  sendCustomerFileWithTypeId
} from '../../../services/workki/CustomerActions';
import ErrorWithShowedMessage from '../../../errors/ErrorWithShowedMessage';
import getCustomerPublicFilePathByFileId from '../../../shared/utils/serviceUtils/getCustomerPublicFilePathByFileId';
import { FILE_TYPE_ID, PUBLIC_PROFILE_MODERATION_STATUS, CUSTOMER_FILE_STATUS } from '../../../constants';
import PrimaryLayout from '../../../layouts/PrimaryLayout';
import { Loader } from '../../../shared/ui/components/Loader';
import { ArrowBack } from '../../../shared/ui/icons/core/ArrowBack';
import {
  cardPaddings,
  ProfileSectionTitleCountWrapper,
  ProfileSectionTopBlock,
  Section,
  SectionImg,
  SectionText
} from '../common/common-components';
import CardShell from '../../../shared/ui/components/Profile/CardShell';
import CustomSwitch from '../../../shared/ui/components/Switch';
import { LogoPlaceholder } from '../../../shared/ui/icons/core/LogoPlaceholder';
import ProfileAvatarModal from './ProfileAvatarModal';
import { Tooltip } from '@mui/material';
import { screenRessolutions } from '../../../shared/ui/constants/styleConstants';
import { useResize } from '../../../shared/utils/hooks/useResize';

const getErrorHandler = (dispatch, defaultMessage) => err => {
  if (err instanceof ErrorWithShowedMessage) {
    return;
  }

  let message = defaultMessage;

  if (err && err.response && err.response.data && isArray(err.response.data)) {
    message = err.response.data[0].message;
  }

  dispatch(error(message, { hideProgressBar: true }));
};

const getProfileFieldsForChange = profile => {
  const values = ['name', 'description', 'url'];
  if (profile && profile.changes_on_moderation) {
    return pick(profile.changes_on_moderation, values);
  }
  return pick(profile, values);
};

const mapStateToProps = state => ({
  customer: customerSelector(state),
  isPublic: !!state.settings.isPublicCustomerProfile,
  moderationStatus: state.settings.publicProfileModerationStatus,
  customerSettingsStatus: requestStatusSelector(actions.requestCustomerSettings)(state),
  customerProfileIsPublicStatus: requestStatusSelector(actions.requestChangeProfileIsPublic)(state),
  publicFiles: customerPublicFilesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  changeCustomerProfileIsPublic: isPublic =>
    dispatch(changeCustomerProfileIsPublic(isPublic)).catch(
      getErrorHandler(dispatch, 'Ошибка переключения видимости профиля.')
    ),
  changeCustomerProfilePublicFields: id => profile => dispatch(changeCustomerProfilePublicFields(id, profile)),
  sendCustomerFile: file =>
    dispatch(sendCustomerFileWithTypeId(FILE_TYPE_ID.PUBLIC, file)).catch(
      getErrorHandler(dispatch, 'Ошибка при загрузке логотипа.')
    )
});

const renderMessageByModerationStatus = (status, shouldShow) => {
  if (status === PUBLIC_PROFILE_MODERATION_STATUS.ALLOWED || !shouldShow) {
    return null;
  }

  if (status === PUBLIC_PROFILE_MODERATION_STATUS.MODERATION) {
    return <div className='profile-social__warning'>Профиль будет опубликован после проверки данных менеджером</div>;
  }

  if (status === PUBLIC_PROFILE_MODERATION_STATUS.REMODERATION) {
    return <div className='profile-social__warning'>Профиль будет опубликован после проверки данных менеджером</div>;
  }

  if (status === PUBLIC_PROFILE_MODERATION_STATUS.NOT_ALLOWED) {
    return (
      <div className='profile-social__warning profile-social__warning--danger'>
        Информация не прошла проверку и не может быть опубликована
      </div>
    );
  }

  return <div className='profile-social__warning'>Ваш социальный профиль не заполнен</div>;
};

const ProfileSocial = ({
  isPublic,
  changeCustomerProfileIsPublic,
  customer,
  changeCustomerProfilePublicFields,
  sendCustomerFile,
  moderationStatus,
  publicFiles,
  customerSettingsStatus,
  customerProfileIsPublicStatus
}) => {
  // const [shouldShowProfile, setShouldShowProfile] = useState(isPublic);
  const [isModalWindowOpened, setIsModalWindowOpened] = useState(false);
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const avatarFile =
    moderationStatus === PUBLIC_PROFILE_MODERATION_STATUS.ALLOWED
      ? last(publicFiles.filter(file => file.status_id === CUSTOMER_FILE_STATUS.VERIFIED))
      : last(publicFiles);

  const dispatch = useDispatch();

  const openAvatarEditor = () => dispatch(actions.openAvatarEditor());
  const closeAvatarEditor = () => dispatch(actions.closeAvatarEditor());

  const imgSrc = avatarFile ? getCustomerPublicFilePathByFileId(avatarFile.id) : null;
  const isLoading = customerSettingsStatus.isPending || customerProfileIsPublicStatus.isPending;
  const fileInputRef = useRef(null);

  const triggerClickToUploadFile = () => {
    fileInputRef.current.click();
  };

  const switchChangeHandler = () => {
    changeCustomerProfileIsPublic(!isPublic);
  };

  const getLoadedFile = () => {
    if (fileInputRef.current) {
      return fileInputRef.current.files[0];
    }
    return null;
  };

  const checkLaptop = () => {
    const width = window.innerWidth >= screenRessolutions.laptop;
    if (width !== isLaptop) setIsLaptop(width);
  };

  useResize(checkLaptop, 0);

  return (
    <PrimaryLayout>
      {isLoading ? (
        <Loader wrapperType='transparent' />
      ) : (
        <div className='profile-social'>
          <div className='profile-social__container'>
            <ProfileSectionTopBlock onClick={() => (window.location.href = '/profile')}>
              <ProfileSectionTitleCountWrapper>
                <ArrowBack
                  defaultColor={'#24272A'}
                  hoverColor={'#383c40'}
                  rotation={0}
                  bigArrow={isLaptop}
                  style={{ minWidth: isLaptop ? '32px' : '28px', height: isLaptop ? '32px' : '28px' }}
                />
                <h1>Социальный профиль</h1>
                <div></div>
              </ProfileSectionTitleCountWrapper>
            </ProfileSectionTopBlock>
            <Tooltip
              arrow
              open={isTooltipOpened}
              title={'Заполните все поля и прикрепите логотип,\n' + 'чтобы опубликовать профиль'}
              placement={'bottom-end'}
              sx={{ zIndex: 100 }}
            >
              <CardShell
                onClick={() => {
                  if (!isPublic && !customer.name && imgSrc) {
                    setIsTooltipOpened(true);
                    if (!tooltipTimeout) {
                      const timeout = setTimeout(() => {
                        setIsTooltipOpened(false);
                        setTooltipTimeout(null);
                      }, 3000);
                      setTooltipTimeout(timeout);
                    }
                  } else {
                    changeCustomerProfileIsPublic(!isPublic);
                  }
                }}
                style={{ padding: cardPaddings, marginBottom: '8px' }}
                minHeight={'fit-content'}
              >
                <Section style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <SectionText>
                    <p>Показывать мой&nbsp;профиль в&nbsp;разделе «Резиденты»</p>
                  </SectionText>
                  <CustomSwitch onChange={switchChangeHandler} size={'medium'} checked={isPublic} />
                </Section>
              </CardShell>
            </Tooltip>
            {renderMessageByModerationStatus(moderationStatus, isPublic)}
            <div className='profile-social__row'>
              <div className='profile-social__logo'>
                {imgSrc ? (
                  <ProfileAvatar
                    src={imgSrc}
                    sendCustomerFile={sendCustomerFile}
                    openEditorCallback={triggerClickToUploadFile}
                  />
                ) : (
                  <LogoPlaceholder onClick={triggerClickToUploadFile} />
                )}
              </div>
              <ProfileSocialForm
                customer={getProfileFieldsForChange(customer)}
                changeCustomerProfilePublicFields={changeCustomerProfilePublicFields(customer ? customer.id : '')}
              />
              <ProfileAvatarModal
                image={getLoadedFile()}
                modalWindowTitle='Добавление изображения'
                sendCustomerFile={sendCustomerFile}
                isModalWindowOpen={isModalWindowOpened}
                closeModalWindow={() => {
                  setIsModalWindowOpened(false);
                  closeAvatarEditor();
                }}
              ></ProfileAvatarModal>
              <input
                ref={fileInputRef}
                onInput={() => {
                  if (fileInputRef.current.files[0]) {
                    openAvatarEditor();
                    setIsModalWindowOpened(true);
                  }
                }}
                className='file-upload__input is-empty'
                id='upload-input'
                type='file'
                accept='image/jpeg, image/png'
              />
            </div>
          </div>
        </div>
      )}
    </PrimaryLayout>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSocial);
