import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../shared/ui/constants/styleConstants';
import { TopBlock } from '../../shared/ui/components/Dialog';
import FormControl from '@mui/material/FormControl';
import CustomDatePicker from '../../shared/ui/components/CustomDatePicker';
import { TabsComponent } from '../../shared/ui/components/Tabs/Tabs';
import Counter from '../../shared/ui/components/Counter';
import CustomSwitch from '../../shared/ui/components/Switch';
import { StepButton } from '../../shared/ui/components/StepButton';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import withBookingWidget from '../../shared/utils/hocs/withBookingWidget';
import { PRICE_COUNT_TYPE } from '../../constants';
import moment from 'moment-timezone';
import Portal, { createContainer } from '../../hocs/Portal';
import { Loader } from '../../shared/ui/components/Loader';
import { removeZeros } from '../../shared/utils/serviceUtils/validationUtils';
import { StepLabel } from '../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { customDaysSelector } from '../../store/selectors/DefaultSelectors';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const TariffsEnum = {
  MONTH: 0,
  DAY: 1,
  TEST_DAY: 2
};

const EntitiesTypesEnum = {
  CABINET: 0,
  FIXED_WORKPLACE: 1,
  UNFIXED_WORKPLACE: 2
};

const TimerBlock = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
  color: ${colors.workkiRedFill};
  margin-top: 4px;
  margin-bottom: 16px;
`;

const TariffInfoBlock = styled.div`
  & > p {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    margin: 8px 0;
  }
`;

const CounterBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 18px 0;
  align-items: center;
  justify-content: space-between;
  & > p {
    width: 135px;
    color: ${colors.workkiDarkSecondary};
    font-size: 14px;
    font-weight: 300;
  }
`;

export const BookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: start;
  padding: 24px 16px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

const WorkplaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 16px;
  & > p:first-of-type {
    font-weight: 400;
  }
  & > p {
    font-weight: 300;
    margin: 0;
  }
  font-size: 14px;
  text-align: start;
`;
const PriceInfo = styled.div`
  display: flex;
  flex-direction: ${props => (props.type === 'price' ? 'column' : 'row')};
  justify-content: flex-start;
  margin-bottom: 16px;
  & > p:first-of-type {
    font-weight: 300;
    margin-right: ${props => (props.type === 'price' ? '0' : '4px')};
  }
  & > p {
    font-weight: 400;
    margin: 0;
  }
  font-size: 14px;
  text-align: start;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > p {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
  }
`;

const BookingWidget = props => {
  const { amount } = props.account;
  const {
    dialogData,
    handleSubmit,
    loadingStatus,
    customer,
    pickedDate,
    setPickedDate,
    currentLocationValue,
    currentSubLocationValue,
    handlePrevStep,
    unfixedWorkplaces,
    getUnfixedWorkplaceTypes
  } = props;
  const LOADER_CONTAINER_ID = 'loader-container';
  const [countOfUnits, setCountOfUnits] = useState(1);
  const [unitType, setUnitType] = useState('');
  const [contract, setContract] = useState({});
  const [isMount, setIsMount] = useState(false);
  const [bookingProlongation, setBookingProlongation] = useState(false);
  const [tariffTabs, setTariffTabs] = useState([{ label: 'Месяц', subtitle: '' }]);
  const [tariffTabValue, setTariffTabValue] = useState(0);
  const [availableUnfixedWorkplaces, setAvailableUnfixedWorkplaces] = useState([]);
  const [currentUnfixedWorkplace, setCurrentUnfixedWorkplace] = useState({});
  const [fullPrice, setFullPrice] = useState(0);
  const [workplaceType, setWorkplaceType] = useState(null);
  const customDays = useSelector(customDaysSelector);
  const defineActualPrice = workplace => {
    return workplace.servicePrices.find(price => price.is_active === 1);
  };

  useEffect(() => {
    if (dialogData.tariff.name.includes('Коворкинг')) {
      setWorkplaceType(EntitiesTypesEnum.FIXED_WORKPLACE);
    } else if (!!dialogData.is_unfixed) {
      setWorkplaceType(EntitiesTypesEnum.UNFIXED_WORKPLACE);
    } else {
      setWorkplaceType(EntitiesTypesEnum.CABINET);
    }
  }, []);
  const handleTabChange = (event, newValue) => {
    const currentTab = tariffTabs.find((tab, index) => index === newValue);
    setTariffTabValue(newValue);
    const pickedWorkplace = availableUnfixedWorkplaces.find(workplace => workplace.id === currentTab.id);
    setFullPrice(displayActivePrice(pickedWorkplace.servicePrices));
    setCurrentUnfixedWorkplace(availableUnfixedWorkplaces.find(workplace => workplace.id === currentTab.id));
  };
  const handleSwitchChange = event => {
    setBookingProlongation(event.target.checked);
  };
  useEffect(() => {
    props.getContracts();
    if (customer.contracts && customer.contracts.length > 0) {
      setContract(customer.contracts);
    }
    if (dialogData) {
      getUnfixedWorkplaceTypes(dialogData.office_id)
        .then(response => {
          const typeInstances = response.data;
          const filteredWorkplaces = unfixedWorkplaces.reduce((acc, workplace) => {
            typeInstances.forEach(typeInstance => {
              if (workplace.id === typeInstance.id) {
                acc.push({ ...workplace, type: typeInstance.type });
              }
            });
            return acc;
          }, []);
          setAvailableUnfixedWorkplaces(filteredWorkplaces);
          setCurrentUnfixedWorkplace(filteredWorkplaces.find(unfixedWorkplace => unfixedWorkplace.type === 'month'));
        })
        .catch(error => {});
      setFullPrice(displayActivePrice(dialogData.servicePrices));
      if (dialogData.is_unfixed === 0) {
        setUnitType('month');
      }
    }
    setContract(getSelectedContract);
    setIsMount(true);
    createContainer({ id: LOADER_CONTAINER_ID });
  }, []);
  //Подготавливаем значения для табов (Для нефиксированных рабочих мест)
  useEffect(() => {
    if (dialogData && dialogData.is_unfixed === 1) {
      const unfixedWorkplacesTabs = availableUnfixedWorkplaces.reduce((acc, workplace) => {
        const activePriceData = defineActualPrice(workplace);
        let tariffLabel = '';
        if (workplace.name.includes('(день)')) {
          tariffLabel = 'День';
        } else if (workplace.name.includes('(тестовый день)')) {
          tariffLabel = 'Тестовый день';
        } else {
          tariffLabel = 'Месяц';
        }
        acc.push({ label: tariffLabel, subtitle: removeZeros(+activePriceData.price) + ' ₽', id: workplace.id });
        return acc;
      }, []);
      setTariffTabs(unfixedWorkplacesTabs);
    }
    setUnitType(currentUnfixedWorkplace.type);
  }, [currentUnfixedWorkplace, availableUnfixedWorkplaces]);

  useEffect(() => {
    if (loadingStatus === 'success' || loadingStatus === 'error') {
      handleSubmit(loadingStatus);
    }
  }, [loadingStatus]);
  const getContractsForSelect = () => {
    if (!contract) return [];

    const contractsForSelect = {
      id: contract.id,
      number: contract.id,
      start_at: contract.date,
      type: contract.type,
      label: `№ ${contract.number} от ${moment(contract.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}`
    };

    return contractsForSelect;
  };
  const getSelectedContract = () => {
    if (contract) {
      return contract;
    } else {
      const { id, type } = getContractsForSelect();

      return {
        contract_id: id,
        type: type
      };
    }
  };
  const displayWorkplaceTitle = (name, tariffName) => {
    if (tariffName.includes('Коворкинг')) {
      return 'Фиксированное рабочее место' + ' ' + name;
    } else return name + ' ' + tariffName;
  };
  const displayDateTitle = (pickedDate, numberOfMonths = 1) => {
    if (!pickedDate) {
      return '';
    }
    const monthAttributes = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря'
    ];

    const monthNumber = pickedDate.month(); // Получаем номер месяца
    const formatedPickedDate = pickedDate.date().toString() + ' ' + monthAttributes[monthNumber];

    if (tariffTabValue === TariffsEnum.DAY || tariffTabValue === TariffsEnum.TEST_DAY) {
      return formatedPickedDate;
    } else {
      // Определяем последнюю дату в зависимости от количества месяцев
      const endDate = pickedDate
        .clone()
        .add(numberOfMonths, 'month')
        .endOf('month');

      // Если передан 1 месяц, возвращаем дату последнего дня месяца
      if (numberOfMonths === 1) {
        const lastDayOfMonth = pickedDate.clone().endOf('month');
        if (pickedDate.date().toString() === lastDayOfMonth.date().toString()) {
          return pickedDate.date().toString() + ' ' + monthAttributes[monthNumber];
        }
        return (
          'с ' + formatedPickedDate + ' по ' + lastDayOfMonth.date().toString() + ' ' + monthAttributes[monthNumber]
        );
      }

      return 'с ' + formatedPickedDate + ' по ' + endDate.date().toString() + ' ' + monthAttributes[endDate.month()];
    }
  };
  const displayActivePrice = pricesInfo => {
    const activePriceInfo = pricesInfo.find(price => price.is_active === 1);
    return activePriceInfo.price;
  };
  useEffect(() => {
    if (fullPrice) {
      if (dialogData && dialogData.is_unfixed === 0) {
        setTariffTabs(prevState =>
          prevState.map(tab => ({ ...tab, subtitle: removeZeros(+fullPrice) + ' ₽', id: dialogData.id }))
        );
      }
    }
  }, [fullPrice]);

  const disableWeekends = day => {
    if (workplaceType === EntitiesTypesEnum.CABINET) {
      return false;
    }
    day = dayjs(day);
    const data = customDays.filter(x => {
      return x.date.toString() === day.format('DD-MM');
    });
    if (data.length > 0) {
      return data[0].status === 0;
    } else {
      return day.day() === 0 || day.day() === 6;
    }
  };

  const calculatePricePerPeriod = (monthsQuantity, startReservationDate) => {
    if (tariffTabValue === TariffsEnum.DAY || tariffTabValue === TariffsEnum.TEST_DAY) {
      return fullPrice;
    }
    let formatedPrice = 0;
    if (
      (dialogData && dialogData.is_unfixed === 0) ||
      (dialogData && dialogData.is_unfixed === 1 && currentUnfixedWorkplace.type === 'month')
    ) {
      const year = startReservationDate.year();
      const month = startReservationDate.month(); // Оставляем месяц без +1, так как dayjs использует 0-индексацию

      // Получаем количество дней в текущем месяце
      const daysInMonth = dayjs(`${year}-${month + 1}`).daysInMonth();

      const daysArray = [];

      // Заполняем массив днями месяца
      for (let day = 1; day <= daysInMonth; day++) {
        daysArray.push(dayjs(`${year}-${month + 1}-${day}`).format('YYYY-MM-DD'));
      }

      // Фильтруем рабочие дни
      // Закомментировал для будущего учета праздничных дней
      // const workingDaysArray = daysArray.filter(day => !(dayjs(day).day() === 0 || dayjs(day).day() === 6));
      // const daysQuantity = workingDaysArray.length;

      // Вычисляем цену за день
      const pricePerDay = fullPrice / daysInMonth;

      // Вычисляем количество рабочих дней с начала резервации
      const fullDaysQuantity = daysArray.filter(day => {
        const currentDay = dayjs(day);
        return currentDay.isAfter(startReservationDate) || currentDay.isSame(startReservationDate, 'day');
      }).length;

      formatedPrice = parseFloat((pricePerDay * fullDaysQuantity).toFixed(2));

      if (monthsQuantity > 1) {
        formatedPrice += +fullPrice * (monthsQuantity - 1);
      }
    } else return fullPrice * countOfUnits;

    return formatedPrice;
  };
  const pricePerPeriod = Math.round(calculatePricePerPeriod(countOfUnits, pickedDate) * 100) / 100;
  const handleBookWorkplace = () => {
    const { handleBuyNow, isGuest, history, customer } = props;

    if (isGuest) {
      history.push({
        pathname: '/prefill-profile',
        state: {
          message: 'Пожалуйста, заполните профиль для пользования полным функционалом личного кабинета.'
        }
      });
      return;
    }

    const place = dialogData && dialogData.is_unfixed === 0 ? dialogData : currentUnfixedWorkplace;
    const start_at = pickedDate.format('YYYY-MM-DD HH:mm:ss');
    const contract = getSelectedContract();
    const price = place.servicePrices.find(
      place => place.cnt_type_id === PRICE_COUNT_TYPE.BY_MONTH || place.cnt_type_id === PRICE_COUNT_TYPE.BY_DAY
    );

    const priceId = fullPrice ? price.id : null;

    handleBuyNow(
      place.id,
      start_at,
      countOfUnits,
      priceId,
      contract,
      bookingProlongation,
      place.office_id,
      customer && customer.is_new
    );
  };

  const handleIncrement = () => {
    setCountOfUnits(prevState => prevState + 1);
  };

  const handleDecrement = () => {
    setCountOfUnits(prevState => {
      if (prevState > 1) {
        return prevState - 1;
      }
      return prevState;
    });
  };

  return (
    <>
      <BookingWrapper>
        <TopBlock direction={'flex-start'} spacing={'16px'}>
          <StepLabel reservation={true} onClick={handlePrevStep}>
            <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} />
            <p onClick={() => console.log('props', props)}>Оплата бронирования</p>
          </StepLabel>
        </TopBlock>
        {/*<TimerBlock>Оплатите в течение 14:45</TimerBlock>*/}
        <FormControl fullWidth={true}>
          <CustomDatePicker
            pickedDate={pickedDate}
            setPickedDate={value => setPickedDate(value)}
            isDayDisabled={disableWeekends}
          />
        </FormControl>
        <TariffInfoBlock>
          <p>Выбранный тариф:</p>
          <TabsComponent
            tabsContainerStyle={{ width: dialogData && dialogData.is_unfixed === 0 ? '130px' : '100%', height: '54px' }}
            tabStyle={{ height: '46px', width: '130px' }}
            tabValue={tariffTabValue}
            handleTabChange={handleTabChange}
            tabs={tariffTabs}
          />
          {tariffTabValue === TariffsEnum.MONTH ? (
            <CounterBlockWrapper>
              <p>Количество месяцев бронирования</p>
              <Counter
                count={countOfUnits}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                type={unitType}
              />
            </CounterBlockWrapper>
          ) : (
            <CounterBlockWrapper />
          )}
          {tariffTabValue !== TariffsEnum.TEST_DAY && (
            <SwitchWrapper>
              <CustomSwitch size='small' spacing='6px' checked={bookingProlongation} onChange={handleSwitchChange} />
              <p>Подключить автопродление</p>
            </SwitchWrapper>
          )}
        </TariffInfoBlock>
      </BookingWrapper>

      <BookingWrapper>
        {dialogData && (
          <WorkplaceInfo>
            <p>{displayWorkplaceTitle(dialogData.name, dialogData.tariff.name)}</p>
            <p>{currentLocationValue + ' ' + currentSubLocationValue}</p>
            <p>{displayDateTitle(pickedDate, countOfUnits)}</p>
            {bookingProlongation && <p>Подключено автопродление</p>}
          </WorkplaceInfo>
        )}
        <PriceInfo type={'price'}>
          <p>К оплате:</p>
          <p>{pricePerPeriod} ₽</p>
        </PriceInfo>
        {Number(pricePerPeriod) > Number(amount) && (
          <PriceInfo type={'checkoutDifference'}>
            <p>Необходимо внести на баланс: </p>
            <p>{Math.round((pricePerPeriod - amount) * 100) / 100} ₽</p>
          </PriceInfo>
        )}
        <FormControl fullWidth={true}>
          <StepButton
            filledType={'red'}
            type={'filled'}
            iconColor={'white'}
            title={Number(pricePerPeriod) > Number(amount) ? 'Пополнить баланс' : 'Забронировать'}
            iconType={'arrow'}
            handleAction={event => {
              if (Number(pricePerPeriod) > Number(amount)) {
                window.location.href = '/payments';
              } else {
                handleBookWorkplace(event);
              }
            }}
          />
        </FormControl>
      </BookingWrapper>
      {isMount && loadingStatus === 'loading' && (
        <Portal id={LOADER_CONTAINER_ID}>
          <Loader />
        </Portal>
      )}
    </>
  );
};

export default withBookingWidget(BookingWidget);
