import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { guestLimitsSelector } from '../../../store/selectors/ServicesSelector';
import dayjs from 'dayjs';
import cn from 'classnames';
import { CustomSelectField } from '../../../shared/ui/components/Select';
import CustomDatePicker from '../../../shared/ui/components/CustomDatePicker';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { styled } from 'styled-components';
import { colors } from '../../../shared/ui/constants/styleConstants';
import { Delete } from '../../../shared/ui/icons/core/Delete';
import { AddGuest } from '../../../shared/ui/icons/core/AddGuest';
import { InfoAlert } from '../../../shared/ui/components/Profile/InfoAlert';
import { KALUZHSKAYA_LOCATION_ID } from '../../../constants';

export const GuestAddLabel = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  margin-top: 16px;
  :hover {
    color: ${colors.workkiDarkFill};
  }
  & > p {
    margin: 0;
    color: ${colors.workkiDarkSecondary};
  }
  font-size: 14px;
`;
const GuestTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 30px;

  & > first-of-type {
    margin-top: 40px;
  }
`;

const GuestPassWidget = ({ guestProps, workplaces, handleClose }) => {
  const { locations, guestCreateRequestStatus, getGuestLimit, addGuests } = guestProps;

  const [visitors, setVisitors] = useState([
    {
      lastName: '',
      firstName: '',
      middleName: '',
      isFocused: {
        lastName: false,
        firstName: false,
        middleName: false
      },
      filled: {
        lastName: false,
        firstName: false,
        middleName: false
      }
    }
  ]);

  const [currentGuestLimit, setCurrentGuestLimit] = useState(0);
  const guestLimitArray = useSelector(guestLimitsSelector);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [formData, setFormData] = useState({
    location: null,
    pickedDate: dayjs()
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    const officeNames = workplaces && workplaces.map(office => office.officeName);
    if (locations && locations.length > 0) {
      const filteredObjects = locations.filter(obj => officeNames.includes(obj.label));
      setFilteredLocations(filteredObjects);
      const options = filteredObjects.map(item => ({ value: item.value.office.id, label: item.label }));
      setSelectOptions(options);
      if (options.length > 0 && formData.location === null) {
        setFormData(prevState => ({ ...prevState, location: options[0].value }));
      }
    }
  }, [workplaces, locations]);

  const selectedLocationData = useMemo(() => {
    return filteredLocations.find(loc => loc.value.office.id === formData.location);
  }, [formData.location, filteredLocations]);

  useEffect(() => {
    if (selectedLocationData) {
      const currentLocationLimits = guestLimitArray.find(el => +el.officeId === selectedLocationData.value.office.id);

      const newGuestLimit = (currentLocationLimits && currentLocationLimits.limit) || 0;
      setCurrentGuestLimit(newGuestLimit);
    }
  }, [formData.location]);
  useEffect(() => {
    if (visitors.length > currentGuestLimit && currentGuestLimit > 0) {
      const reducedVisitors = visitors.slice(0, currentGuestLimit);
      setVisitors(reducedVisitors);
    }
  }, [currentGuestLimit]);
  useEffect(() => {
    getGuestLimit(formData.pickedDate.startOf('day'));
  }, [formData.pickedDate]);
  useEffect(() => {
    if (guestLimitArray.length > 0 && formData.location) {
      const currentLocationLimits = guestLimitArray.find(el => +el.officeId === formData.location);
      const newGuestLimit = (currentLocationLimits && currentLocationLimits.limit) || 0;
      setCurrentGuestLimit(newGuestLimit);
    }
  }, [guestLimitArray, formData.pickedDate]);

  useEffect(() => {
    const allFieldsFilled = visitors.every(
      visitor =>
        visitor.lastName &&
        visitor.lastName.trim() !== '' &&
        visitor.firstName &&
        visitor.firstName.trim() !== '' &&
        visitor.middleName &&
        visitor.middleName.trim() !== ''
    );

    setIsSubmitDisabled(!allFieldsFilled || visitors.length === 0);
  }, [visitors]);
  useEffect(() => {
    if (guestCreateRequestStatus && guestCreateRequestStatus.isSuccess) {
      handleClose();
    }
  }, [guestCreateRequestStatus]);

  const setLocation = event => {
    setFormData(prevState => ({ ...prevState, location: event.target.value }));
  };

  const handleAddGuest = e => {
    e.preventDefault();
    if (visitors.length < currentGuestLimit) {
      setVisitors([
        ...visitors,
        {
          lastName: '',
          middleName: '',
          isFocused: {
            lastName: false,
            firstName: false,
            middleName: false
          },
          filled: {
            lastName: false,
            firstName: false,
            middleName: false
          }
        }
      ]);
    }
  };

  const handleRemoveGuest = indexToRemove => e => {
    e.preventDefault();
    setVisitors(visitors.filter((_, index) => index !== indexToRemove));
  };

  const handleInputChange = (index, fieldName, value) => {
    setVisitors(prevVisitors => {
      const updatedVisitors = [...prevVisitors];
      updatedVisitors[index][fieldName] = value;
      updatedVisitors[index].filled[fieldName] = value !== '';
      return updatedVisitors;
    });
  };

  const handleFocus = (index, fieldName) => {
    setVisitors(prevVisitors => {
      const updatedVisitors = [...prevVisitors];
      updatedVisitors[index].isFocused[fieldName] = true;
      return updatedVisitors;
    });
  };

  const handleBlur = (index, fieldName) => {
    setVisitors(prevVisitors => {
      const updatedVisitors = [...prevVisitors];
      updatedVisitors[index].isFocused[fieldName] = false;
      return updatedVisitors;
    });
  };

  const handleEraseValue = (index, fieldName) => {
    handleInputChange(index, fieldName, '');
  };
  const handleSubmit = () => {
    const dateTo = dayjs(formData.pickedDate)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 58);

    const service_id = selectedLocationData.value.services[0].id;

    const guests = visitors.map(visitor => ({
      ...visitor,
      service_id,
      start_at: dayjs(formData.pickedDate).format('YYYY-MM-DD HH:mm:ss'),
      finish_at: dateTo.format('YYYY-MM-DD HH:mm:ss')
    }));

    addGuests(guests);
  };
  const displayGuestPassesAnnotation = guestLimit => {
    if (guestLimit % 100 >= 11 && guestLimit % 100 <= 14) {
      return 'гостевых пропусков';
    }
    if (guestLimit % 10 === 1) {
      return 'гостевой пропуск';
    }
    if (guestLimit % 10 >= 2 && guestLimit % 10 <= 4) {
      return 'гостевых пропуска';
    }
    return 'гостевых пропусков';
  };
  const kaluzhskayaSubtitle = (
    <span style={{ fontSize: '12px' }}>
      <strong>Гость может находиться на локации не более двух часов!</strong>
      <br />
      Не распространяется на тарифы 1 день, Тест Драйв и на гостей переговорных на все время бронирования.
    </span>
  );
  return (
    <>
      <InfoAlert
        title={
          currentGuestLimit > 0
            ? `В тарифе осталось ${currentGuestLimit} ${displayGuestPassesAnnotation(currentGuestLimit)}`
            : ''
        }
        subTitle={
          formData.location === KALUZHSKAYA_LOCATION_ID
            ? kaluzhskayaSubtitle
            : `Гость может находиться на локации не более двух часов`
        }
        type={'info'}
      />
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleSubmit}
        autoComplete='off'
        noValidate
      >
        <FormControl fullWidth={true}>
          <CustomSelectField
            name='location'
            id='custom-select'
            label={'Локация'}
            variant='filled'
            isDisabled={selectOptions.length === 0 || selectOptions.length === 1}
            value={formData.location || ''}
            onChange={setLocation}
            options={selectOptions}
          />
          <CustomDatePicker
            customLabel='Дата гостевого визита'
            pickedDate={formData.pickedDate}
            setPickedDate={newValue => setFormData(prevState => ({ ...prevState, pickedDate: newValue }))}
          />
          {currentGuestLimit > 0 && (
            <>
              {visitors.map((visitor, index) => (
                <div key={index} className='fill-payments-modal__guestRow'>
                  <GuestTitleRow>
                    <span>Гость {index + 1}</span>
                    <Delete
                      handleAction={handleRemoveGuest(index)}
                      defaultColor={colors.workkiDarkMiddle}
                      spacing={'0'}
                      hoverColor={colors.workkiDarkSecondary}
                    />
                  </GuestTitleRow>
                  <CustomTextField
                    error={''}
                    type='text'
                    required={true}
                    name={`visitors[${index}].lastName`}
                    id='outlined-helperText'
                    label='Фамилия'
                    variant='filled'
                    value={visitor.lastName}
                    onFocus={() => handleFocus(index, 'lastName')}
                    onBlur={() => handleBlur(index, 'lastName')}
                    onChange={e => handleInputChange(index, 'lastName', e.target.value)}
                    helperText={''}
                    endAdornmentProps={{
                      filled: visitor.filled.lastName,
                      isFocused: visitor.isFocused.lastName,
                      handleEraseValue: () => handleEraseValue(index, 'lastName')
                    }}
                    autoFocus={true}
                  />
                  <CustomTextField
                    error={''}
                    required={true}
                    type='text'
                    name={`visitors[${index}].firstName`}
                    id='outlined-helperText'
                    label='Имя'
                    variant='filled'
                    value={visitor.firstName}
                    onFocus={() => handleFocus(index, 'firstName')}
                    onBlur={() => handleBlur(index, 'firstName')}
                    onChange={e => handleInputChange(index, 'firstName', e.target.value)}
                    helperText={''}
                    endAdornmentProps={{
                      filled: visitor.filled.firstName,
                      isFocused: visitor.isFocused.firstName,
                      handleEraseValue: () => handleEraseValue(index, 'firstName')
                    }}
                    autoFocus={false}
                  />
                  <CustomTextField
                    error={''}
                    type='text'
                    required={true}
                    name={`visitors[${index}].middleName`}
                    id='outlined-helperText'
                    label='Отчество'
                    variant='filled'
                    value={visitor.middleName}
                    onFocus={() => handleFocus(index, 'middleName')}
                    onBlur={() => handleBlur(index, 'middleName')}
                    onChange={e => handleInputChange(index, 'middleName', e.target.value)}
                    helperText={''}
                    endAdornmentProps={{
                      filled: visitor.filled.middleName,
                      isFocused: visitor.isFocused.middleName,
                      handleEraseValue: () => handleEraseValue(index, 'middleName')
                    }}
                    autoFocus={false}
                  />
                </div>
              ))}
              {visitors.length < currentGuestLimit && (
                <GuestAddLabel onClick={handleAddGuest}>
                  <AddGuest
                    spacing={'2px'}
                    hoverColor={colors.workkiDarkFill}
                    defaultColor={colors.workkiDarkSecondary}
                  />
                  <p>Добавить гостя</p>
                </GuestAddLabel>
              )}
            </>
          )}
          {currentGuestLimit === 0 && selectOptions.length > 0 && (
            <InfoAlert
              title={'На данной локации в этот день превышен лимит доступных гостевых пропусков'}
              type={'warning'}
            />
          )}
          {selectOptions.length === 0 && (
            <InfoAlert title={'Чтобы оформить гостевой пропуск - необходимы активные аренды'} type={'warning'} />
          )}
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title='Отправить заявку'
              handleAction={handleSubmit}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

GuestPassWidget.propTypes = {
  guestProps: PropTypes.object.isRequired,
  workplaces: PropTypes.array.isRequired
};

export default GuestPassWidget;
