import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { WorkkiLogo } from '../shared/ui/icons/core/workkiLogo';
import { connect, useDispatch, useSelector } from 'react-redux';
import { customerSelector } from '../store/selectors/DefaultSelectors';
import Avatar from '@mui/material/Avatar';
import {
  ProfileCardTitle,
  ProfileCardWrapper,
  Card,
  ProfileCardCaption,
  ProfileCardList
} from '../shared/ui/components/Profile/ProfileCard';
import DocumentsList from '../shared/ui/components/Profile/DocumentsList';
import { logoutUser } from '../entities/Authentification/api/AuthActions';
import { getCustomerContracts } from '../services/workki/CustomerActions';
import { LogoutIcon } from '../shared/ui/icons/core/LogoutIcon';
import { LinkArrow } from '../shared/ui/icons/core/LinkArrow';
import { PrefillForm } from '../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { stringAvatar } from '../shared/utils/helperFunctions/heplerFunctions';

const screenRessolutions = {
  mobile: 360,
  tablet: 768,
  laptop: 1366,
  desktop: 1920,
  desktopPlus: 2560
};

const ContentPage = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

const ProfileInfoWidget = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    margin-top: 32px;
  }

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin-top: 44px;
  }
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    margin: 0;
  }
  :first-child {
    font-weight: 500;
    color: #24272a;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  :nth-child(2) {
    font-weight: 400;
    color: #24272a;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ProfileID = styled.div`
  margin: 20px 0 8px 0;
  padding: 0 16px;
  box-shadow: 0px 2px 12px 0px #00000014;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 12px;
  & > p {
    margin: 0;
    font-weight: 500;
  }
  :first-child {
    font-size: 15px;
    line-height: 16px;
  }
  :nth-child(2) {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  @media screen and (min-width: ${screenRessolutions.laptop}px) {
    margin-top: 32px;
  }
`;
export const LogoutCardWrapper = styled.div`
  & > div > p {
    margin: 0;
  }
  padding: 0 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > svg {
    align-self: center;
    justify-self: center;
    & > path {
      width: 7px;
      height: 12px;
    }
  }
  box-shadow: 1px 2px 12px 1px #00000014;
`;

const mapStateToProps = state => ({
  contracts: customerSelector(state).contract
});

const mapDispatchToProps = dispatch => ({
  getContracts: () => dispatch(getCustomerContracts())
});

const ProfilePage = ({ getContracts, contracts }) => {
  const dispatch = useDispatch();
  const makeLogout = useCallback(() => dispatch(logoutUser()), [dispatch]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= screenRessolutions.laptop);

  const customer = useSelector(customerSelector);

  const getDocumentsArr = () => {
    if (Array.isArray(contracts)) return contracts;

    if (typeof contracts === 'object') return [contracts];

    return [];
  };

  let cards = [
    // {
    //   title: 'Платежный профиль',
    //   caption: 'Реквизиты компании и данные руководителя'
    // },
    {
      route: '/employees',
      title: 'Сотрудники',
      caption: 'Учетные записи ваших сотрудников'
    },
    {
      route: '/social',
      title: 'Социальный профиль',
      caption: 'Видимость профиля в разделе «Резиденты»'
    }
  ];

  // const cardsForBottom = [
  //   {
  //     title: 'Настройки профиля',
  //     caption: ''
  //   }
  // ];

  const getDocumentsMargin = () => {
    if (window.innerWidth < screenRessolutions.laptop) return { margin: '28px 0 32px 0' };
    return { margin: '36px 0' };
  };

  window.addEventListener('resize', e => {
    if (e.target.innerWidth >= screenRessolutions.laptop) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', e => {
        if (e.target.innerWidth >= screenRessolutions.laptop) {
          setIsDesktop(true);
        } else {
          setIsDesktop(false);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!contracts) {
      getContracts();
    }
  }, [contracts, getContracts]);

  return (
    <>
      <PrefillForm>
        {!isDesktop && <WorkkiLogo width={'140px'} height={'30px'} />}
        <ContentPage>
          {customer && (
            <ProfileInfoWidget>
              <Avatar
                sx={{ color: '#8F9295', fontWeight: 500, backgroundColor: '#F4F4F4', marginRight: '12px' }}
                {...stringAvatar(customer.name)}
              />
              <ProfileInfo>
                <p>{customer.name}</p>
                <p>{customer.email}</p>
              </ProfileInfo>
            </ProfileInfoWidget>
          )}
          <ProfileID>
            <p>ID аккаунта:</p>
            <p>{customer.id}</p>
          </ProfileID>
          <ProfileCardList>
            {cards.map((card, index) => (
              <ProfileCardWrapper key={index} onClick={() => (window.location.href = card.route)}>
                <Card>
                  <ProfileCardTitle>{card.title}</ProfileCardTitle>
                  <ProfileCardCaption>{card.caption}</ProfileCardCaption>
                </Card>
                <LinkArrow defaultColor='#8F9295' width={'16px'} height={'16px'} />
              </ProfileCardWrapper>
            ))}
          </ProfileCardList>
        </ContentPage>
        {getDocumentsArr().length > 0 && (
          <DocumentsList spacing={getDocumentsMargin()} documentsArr={getDocumentsArr()} />
        )}
        <LogoutCardWrapper onClick={makeLogout}>
          <Card style={{ flexDirection: 'row' }}>
            <LogoutIcon defaultColor={'#000000'} spacing={'4px'} onClick={makeLogout} />
            <ProfileCardTitle>Выйти</ProfileCardTitle>
          </Card>
        </LogoutCardWrapper>
        {/*<ProfileCard*/}
        {/*  cards={cardsForBottom}*/}
        {/*  logoutShown={true}*/}
        {/*  handleLogout={makeLogout}*/}
        {/*  handleFollowLink={() => {*/}
        {/*    setStep('profileConfirmation');*/}
        {/*  }}*/}
        {/*/>*/}
      </PrefillForm>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
