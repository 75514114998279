import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  // margin-right: ${props => props.spacing || '16px'};
  width: ${props => props.width || '12px'};
  height: ${props => props.height || '12px'};
`;

const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;
  color: rgba(143, 146, 149, 1);
  font-weight: 500;
  font-size: 14px;
`;

export const LogoPlaceholder = ({ defaultColor, spacing, onClick }) => {
  return (
    <SvgContainer onClick={onClick}>
      <StyledSvg
        width='96'
        height='96'
        viewBox={`0 0 80 80`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        fillColor={defaultColor}
        spacing={spacing}
      >
        <rect width='80' height='80' rx='40' fill='#F4F4F4' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M43.375 32.125C40.8897 32.125 38.875 34.1397 38.875 36.625C38.875 39.1103 40.8897 41.125 43.375 41.125C45.8603 41.125 47.875 39.1103 47.875 36.625C47.875 34.1397 45.8603 32.125 43.375 32.125ZM41.125 36.625C41.125 35.3824 42.1324 34.375 43.375 34.375C44.6176 34.375 45.625 35.3824 45.625 36.625C45.625 37.8676 44.6176 38.875 43.375 38.875C42.1324 38.875 41.125 37.8676 41.125 36.625Z'
          fill='#8F9295'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M27.625 31C27.625 29.136 29.136 27.625 31 27.625H49C50.864 27.625 52.375 29.136 52.375 31V49C52.375 50.864 50.864 52.375 49 52.375H31C29.136 52.375 27.625 50.864 27.625 49V31ZM29.875 49V44.9659L33.5795 41.2614C34.0188 40.8221 34.7312 40.8221 35.1705 41.2614L44.0341 50.125H31C30.3787 50.125 29.875 49.6213 29.875 49ZM49 50.125H47.2161L36.7615 39.6704C35.4435 38.3524 33.3065 38.3524 31.9885 39.6704L29.875 41.7839V31C29.875 30.3787 30.3787 29.875 31 29.875H49C49.6213 29.875 50.125 30.3787 50.125 31V49C50.125 49.6213 49.6213 50.125 49 50.125Z'
          fill='#8F9295'
        />
      </StyledSvg>
      <span>Добавить логотип</span>
    </SvgContainer>
  );
};
